import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
  return (
    <div className="w-full bg-lblue">
      <div className="mx-auto  pt-10">
        <div className="flex text-center items-center">
          <div className="md:w-1/2 md:block hidden">
            <div className="w-full">
              <StaticImage src="../../images/buyerbg.png" alt="" className=" w-full h-auto" />
            </div>
          </div>
          <div className="md:w-1/2 md:max-w-xl md:pl-6 text-center ">
            <h1 className="text-6xl mx-auto pb-16">
              Compra un negocio digital en 30 días
            </h1>
            <p className=" text-lg text-dpurple pb-16">Encuentra entre el listado de proyectos los que encajen mejor en tu plan. Revisa las métricas clave y contacta directamente con el emprendedor. Todos los proyectos son revisados por el equipo de StartupOnSale.</p>
            <div className="flex mb-10 md:mb-0 mx-auto w-fit">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="blue-button w-auto">Quiero ver proyectos</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
