import React from "react";

const BuyStartup = () => {
  return (
      <div className="max-w-6xl mx-auto py-14 justify-center text-center">
        <h3 className="text-4xl text-bblue pb-10">
          ¡Compra un proyecto en pocos pasos!
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Card
            nmb="01."
            title="Registrate"
            titleColor="#5769b1"
            description="Date de alta en nuestra plataforma para empezar el proceso."
          />
          <Card
            nmb="02."
            title="Busca tú proyecto"
            titleColor="#ee2163"
            description="Filtra el listado de proyectos hasta encontrar el que estás buscando."
          />
          <Card
            nmb="03."
            title="Contacta"
            titleColor="#170f2e"
            description="Envía un mensaje al emprendedor desde la plataforma para empezar la negociación."
          />
        </div>
      </div>
  );
};

export default BuyStartup;

const Card = ({title, titleColor, description, nmb}) => {
  return (
    <div className="bg-white border-2 border-dgrey rounded-2xl px-5 py-4">
      <h1 className="text-4xl md:text-5xl opacity-20 font-bold text-dpurple nexaTextBold pb-2 pt-2">
        {nmb}
      </h1>
      <h1
        className="text-2xl md:text-3xl font-bold text-dpurple nexaTextBold pb-4"
        style={{color: titleColor}}
      >
        {title}
      </h1>
      <p
        className="text-lg md:text-xl text-dpurple font-normal NexaTextDemoLight pb-2"
        style={{wordSpacing: "8px"}}
      >
        {description}
      </p>
    </div>
  );
};
