import React from "react";
import Index from "../views/Buyers";

const Buyers = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default Buyers;
