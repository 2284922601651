import React from "react";
import { Layout } from "@startuponsale/gatsby-shared-ui";
import Header from "./Header";
import BuyStartup from "./BuyStartup";
import LookingForProject from "./LookingForProject";
import CallToAction from "../../components/CallToAction";
import Services from "../../components/Services"

const Index = () => {
  return (
    <div>
      <Layout>
        <Header />
        <BuyStartup />
        <LookingForProject />
        <Services />
        <CallToAction title="Únete a la primera la comunidad hispana de compra/venta de negocios." 
                      action="Darme de alta" 
                      link={process.env.APP_URL + "/registro/nuevo"}/>
      </Layout>
    </div>
  );
};

export default Index;
