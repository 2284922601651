import React from "react";
import {GoPrimitiveDot} from "react-icons/go";
import { StaticImage } from "gatsby-plugin-image";


const LookingForProject = () => {
  return (
    <div className="max-w-6xl mx-auto py-10">
        <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-2 mb-20">
          <div className=" md:max-w-2xl">
            <div className="pl-20 md:pr-20 md:pl-0">
            <div className="pb-16 w-fit text-dpurple">
                <h2 className="text-4xl text-bblue">
                  ¿Estás buscando un proyecto...
                </h2>
                <p className="text-lg justify">Encontrar el proyecto perfecto es difícil y gestionar un proceso de compra transparente y fiable lo es aún más. Te ayudamos.</p>
              </div>
              
              <Text iconColor="#5769b1">para <p className="nexabold inline">escalar</p> tu startup actual?</Text>
              <Text iconColor="#5769b1">pero el proceso de <p className="nexabold inline">due diligence</p> se te hace pesado?</Text>
              <Text iconColor="#5769b1">no encuentras <p className="nexabold inline">proyectos interesantes</p>?</Text>
              <Text iconColor="#5769b1">necesitas <p className="nexabold inline">ayuda</p> en el proceso de compra?.</Text>
              <div className="mx-auto justify-center flex w-6/12 pt-5">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="blue-button w-auto">
                  Busco proyectos 
                </a>
              </div>
            </div>
          </div>

          <div className="rounded-lg shadow-xl">
            <StaticImage
              src="../../images/Group 18 (copy).png"
              alt="project"
              className="rounded-2xl md:max-w-md object-cover shadow-xl projectimg"
              placeholder="blurred"
              layout="fixed" height={500} width={500}
            />
          </div>
        </div>
      </div>
  );
};

export default LookingForProject;

const Text = ({children, iconColor}) => {
  return (
    <div className="flex pb-3">
      <span>
        <GoPrimitiveDot className="w-7 h-7" style={{color: iconColor}} />
      </span>
      <span className="text-lg nexalight pl-1.5">
        {children}
      </span>
    </div>
  );
};
